.lg-react-element {
    column-count: 3;
    column-gap: 10px
}

.galleryImg {
    max-width: 100%;
    display: block;
    padding: 5px 0px;
    border-radius: 24px;
}
