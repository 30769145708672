.zoom-in-out-box {
  width: 100%;
  height: 100%;
  animation: zoomIn 7s infinite;
  overflow: hidden;
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
    width: 100%;
    height: 100%;
  }

  100% {
    transform: scale(1.2);
    width: 100%;
    height: 100%;
  }
}
