.cityContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1rem 0;
    /* align-items: flex-start; */
    /* justify-content: space-around; */
    /* width: 363px;
     height:420px; */
      /* Top 201px Left 144px  */
    /* border-radius: 24px; */
}

.cityImgContainer{
    flex: 1;
    display: flex;
    flex-direction: column;
    /* width: 363px;
    height: 420px; */
}

.cityImg {
    border-radius: 24px 24px 0 0;
    background-size: contain;
    flex: 1
}

.cityTitle{
    position: relative;
    bottom: 10%;
    left: 4%;
    color: white;
    font-size: 24px;
}

.viewLocationsContainer{
    flex: 1;
    background-color:#34255C;
    color: white;
    display: flex;
    padding: 1.2rem;
    border-radius: 0 0 24px 24px ;
}