
.slider {
  margin:0 20px;
  overflow:"hidden";
  padding:2rem 0;
}

.slider img {
  width: 100%;
  border-radius:10px;
}

.react-multi-carousel-list {
  padding: 1rem 0 2rem 0;
}

.custom-dot-list-style button{
  background-color: #D9D9D9;
  border: 1px solid #D9D9D9;
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button{
  background-color: #34255C;
  width: 5rem;
  border-radius: 8px;
  color: #34255C,
}

