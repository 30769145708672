@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap");
.razorpay-container {
  @media only screen and (max-width: 450px) {
    max-height: 600px !important;
    margin: auto;
  }
}

.razorpay-container iframe {
  @media only screen and (max-width: 460px) {
    height: 600px !important;
    width: 100% !important;
  }
}

.h1,
.h2,
.h3 {
  font-family: inherit;
  font-size: inherit;
}
